/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { buttonToolOpts } from './unlayer-button-tool/file_download_tool.constants';
import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';

export function KeapFileDownloadViewer(props = {}) {
    const { values = {} } = props;

    const selectedFileUrl = values?.fileDownloadEditor?.selectedFile?.url || '#placeholder';

    const borderRadii = values.buttonBorderRadius.split(' ');

    let borderTopLeftRadius = borderRadii[0];
    let borderTopRightRadius = borderRadii[0];
    let borderBottomRightRadius = borderRadii[0];
    let borderBottomLeftRadius = borderRadii[0];

    if (borderRadii.length !== 1) {
        borderTopLeftRadius = borderRadii[0];
        borderTopRightRadius = borderRadii[1];
        borderBottomRightRadius = borderRadii[2];
        borderBottomLeftRadius = borderRadii[3];
    }

    const { color, backgroundColor, hoverColor, hoverBackgroundColor } = values.buttonColors;
    const calculatedButtonWidth = (values.buttonWidth.autoWidth === true)
        ? 'fit-content'
        : values.buttonWidth.width;

    return (
        <div
            className="booking-outer-container"
            // eslint-disable-next-line react/no-unknown-property
            align={values.buttonAlign}
        >
            <table
                className="booking-inner-container"
                style={{
                    width: calculatedButtonWidth,
                    margin: values.buttonMargin,
                    borderCollapse: 'separate',
                }}
            >
                <tr>
                    <td
                        style={{
                            textAlign: 'center',
                            backgroundColor,
                            hoverBackgroundColor,
                            ...values.buttonBorder,
                            borderTopLeftRadius,
                            borderTopRightRadius,
                            borderBottomRightRadius,
                            borderBottomLeftRadius,
                        }}
                    >
                        <a
                            href={selectedFileUrl}
                            target='_blank'
                            rel="noopener noreferrer"
                            style={{
                                display: 'inline-block',
                                fontSize: values.buttonFontSize,
                                fontFamily: values.buttonFontFamily.value,
                                textDecoration: 'none',
                                lineHeight: values.buttonLineHeight,
                                color,
                                hoverColor,
                                width: '100%',
                                padding: values.buttonPadding,
                                wordWrap: 'break-word',
                                boxSizing: 'border-box',
                            }}
                        >
                            { values.buttonText }
                        </a>
                    </td>
                </tr>
            </table>
        </div>
    );
}
class FileDownloadEditor extends React.Component {
    constructor(props) {
        super(props);

        const { data = {} } = props;

        this.state = {
            messageChannelHost: data.messageChannelHost,
            selectedFile: {
                name: '',
                url: '',
            },
        };

        this.updateSelectedFile = this.updateSelectedFile.bind(this);
        this.updateUnlayerValueState = this.updateUnlayerValueState.bind(this);
        this.openKeapFileUploadModal = this.openKeapFileUploadModal.bind(this);
    }

    componentDidMount() {
        const { value } = this.props;
        const selectedFile = value.selectedFile;

        if (selectedFile) {
            this.setState({ selectedFile });
        }
    }

    updateSelectedFile(selectedFile) {
        this.setState({ selectedFile });
        this.updateUnlayerValueState({ selectedFile });
    }

    updateUnlayerValueState(newValueState) {
        const { value, updateValue } = this.props;

        // forces the transformer to see the update
        updateValue('');

        updateValue({
            ...value,
            ...newValueState,
        });
    }

    async openKeapFileUploadModal() {
        const selectedFile = await executeFrameRequest('keapFileUploadRequest', null, this.state.messageChannelHost, 'parent');

        this.updateSelectedFile(selectedFile);
    }

    render() {
        const pragraphStyle = {
            color: '#515151',
            fontSize: '12px',
            fontWeight: 400,
        };

        const selectedFileStyle = {
            marginTop: '1rem',
            ...pragraphStyle,
        };

        const buttonStyle = {
            backgroundColor: '#f4f4f4',
            border: '1px solid #f4f4f4',
            borderRadius: '2px',
            color: '#262626',
            cursor: 'pointer',
            fontSize: '0.85rem',
            fontWeight: '400',
            height: '40px',
            marginTop: '1rem',
            padding: '0.4rem 0.85rem',
        };

        return (
            <div>
                <p style={pragraphStyle}>You can select or upload a file using the File Manager.</p>

                <button style={buttonStyle} onClick={this.openKeapFileUploadModal}>Open File Manager</button>

                <p style={selectedFileStyle}>Selected file: {this.state.selectedFile.name || 'None'}</p>
            </div>
        );
    }
}

export const KeapFileDownloadPropertyEditor = {
    name: 'file_download_editor',
    layout: 'bottom',
    Widget: FileDownloadEditor,
};

export const KeapEmailFileDownloadTool = (unlayerEmailFileDownloadLinksEnabled) => ({
    name: 'unlayerFileDownloadTool',
    label: 'File',
    icon: '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.3332 4.00002C17.3332 3.26365 16.7362 2.66669 15.9998 2.66669C15.2634 2.66669 14.6665 3.26365 14.6665 4.00002V18.1144L10.276 13.7239C9.75528 13.2032 8.91106 13.2032 8.39036 13.7239C7.86966 14.2446 7.86966 15.0888 8.39036 15.6095L15.057 22.2762C15.5777 22.7968 16.422 22.7968 16.9426 22.2762L23.6093 15.6095C24.13 15.0888 24.13 14.2446 23.6093 13.7239C23.0886 13.2032 22.2444 13.2032 21.7237 13.7239L17.3332 18.1144V4.00002Z" fill="black" fill-opacity="0.6"/><path d="M3.99984 18.6667C4.73621 18.6667 5.33317 19.2636 5.33317 20V26.6667H26.6665V20C26.6665 19.2636 27.2634 18.6667 27.9998 18.6667C28.7362 18.6667 29.3332 19.2636 29.3332 20V26.6667C29.3332 28.1395 28.1393 29.3334 26.6665 29.3334H5.33317C3.86041 29.3334 2.6665 28.1395 2.6665 26.6667V20C2.6665 19.2636 3.26346 18.6667 3.99984 18.6667Z" fill="black" fill-opacity="0.6"/></svg>',
    supportedDisplayModes: ['email'],
    enabled: !unlayerEmailFileDownloadLinksEnabled,
    options: {
        fileDownload: {
            position: 1,
            title: 'File download',
            options: {
                fileDownloadEditor: {
                    label: 'File',
                    defaultValue: {},
                    widget: 'file_download_editor',
                },
            },
        },
        ...buttonToolOpts(),
    },
    transformer: (values) => {
        return values;
    },
    values: {},
    renderer: {
        Viewer: KeapFileDownloadViewer,
        exporters: {
            email(values) {
                const props = { values, displayMode: 'email' };

                return ReactDOMServer.renderToStaticMarkup(<KeapFileDownloadViewer {...props} />);
            },
        },
    },
});


